import {
  createBlocksModel,
} from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Bottom of Page 3',
  props: {},
  methods: {},
  events: {},
});
