import type { PostPageRenderModel } from '../../external/blocks-widget/post-page-render-model';
import model from './model';

const selectors = {
  root: '#ratingsRoot',
  ratings: '#ratingsDisplay',
} as const;

export default model.createController(({ $w, flowAPI }) => {
  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    root: $w(selectors.root) as $w.Box,
    ratings: $w(selectors.ratings) as $w.RatingsDisplay,
  });

  return {
    pageReady: async () => {
      components = getComponents();
    },
    exports: {
      initialize: (renderModel: PostPageRenderModel) => {
        const displayRatings =
          (renderModel.metadata.averageRating > 0 &&
            flowAPI.environment.isViewer) ||
          flowAPI.environment.isEditor;

        const rating =
          renderModel.metadata.averageRating > 0
            ? renderModel.metadata.averageRating
            : undefined;

        if (displayRatings) {
          // @ts-expect-error
          components.ratings.rating = flowAPI.environment.isEditor ? 3 : rating;
          components.ratings.numRatings = flowAPI.environment.isEditor
            ? 4
            : renderModel.metadata.totalRatings;
        } else {
          components.root.delete();
        }
      },
    },
  };
});
