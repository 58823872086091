import {
  BLOG_APP_DEBUG,
  LOG_BI_TO_CONSOLE,
  LOG_REDUX_ACTIONS,
  LOG_BLOG_POST_EDITOR_ACTIONS,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import {
  AMP_BASE_URLS,
  WP_BLOGS,
} from '@wix/communities-universal/dist/src/constants/wix-blogs';
import {
  setAppConfig as setAppConfigAction,
  GLOBALS,
} from '@wix/communities-blog-client-common';
import getEnvironment from '../../services/get-environment';
import { getTopology } from '../topology/topology-selectors';

export function setAppConfig({ bundleName }) {
  return (dispatch, getState, { wixCodeApi, appParams }) => {
    dispatch(
      setAppConfigAction(
        getConfig({
          appParams,
          wixCodeApi,
          state: getState(),
          bundleName,
        }),
      ),
    );
  };
}

function getConfig({ appParams, wixCodeApi, state, bundleName }) {
  const isProduction = process.env.NODE_ENV === 'production';
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);

  const topology = getTopology(state);

  const developmentConfig = {
    isDevMode: !isProduction,
    debugEnabled: BLOG_APP_DEBUG,
    logBiToConsole: LOG_BI_TO_CONSOLE,
    logReduxActions: LOG_REDUX_ACTIONS,
    logPostEditorActions: LOG_BLOG_POST_EDITOR_ACTIONS,
  };
  const isSeo = wixCodeApi.seo.isInSEO();
  const isWP = WP_BLOGS.includes(appParams.instanceId);

  const { baseUrl, postPageSectionUrl } = topology;
  const isAmpEnabled = state?.tpaSettings?.settings?.ampEnabled ?? false;
  const ampBaseUrl = `${baseUrl}/amp`;
  const wpAmpBaseUrl = AMP_BASE_URLS[appParams.instanceId];

  const clientConfig = {
    bundleName,
    imageHost: GLOBALS.MEDIA_IMAGE_HOST,
    videoHost: GLOBALS.MEDIA_VIDEO_HOST,
    instanceId: appParams.instanceId,
    duplexer: {
      url: GLOBALS.DUPLEXER_URL,
    },
    isInEditor: isEditor,
    isInPreview: isPreview,
    isWP,
    categoryPath: isWP ? 'category' : 'categories',
    useCategoryMenuLabelForMetadataTitle: isWP && !isSeo,
    ampBaseUrl: isAmpEnabled && (wpAmpBaseUrl || baseUrl && ampBaseUrl),
    postPageSectionUrl,
    ...developmentConfig,
  };

  return clientConfig;
}
