import {
  createBlocksModel,
} from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Top of Page',
  props: {},
  methods: {},
  events: {},
});
